<template lang="pug">
button.transition-colors.duration-300.bg-white.h-9.w-9.hover_bg-reallylightblue.rounded-full.shadow.focus_outline-none.relative.flex.items-center.justify-center(
  type='button',
  @click='$emit("toggleNotification")'
)
  svg(
    width='24',
    height='24',
    viewBox='0 0 24 24',
    fill='none',
    xmlns='http://www.w3.org/2000/svg'
  )
    path(
      fill-rule='evenodd',
      clip-rule='evenodd',
      d='M11 5.96078C11 5.43016 11.4302 5 11.9608 5H12.0392C12.5698 5 13 5.43016 13 5.96078C13 6.54324 13.4741 7 14.0395 7C15.1201 7 16 7.87761 16 8.94118V12.9608C16 13.5347 16.4653 14 17.0392 14C17.5571 14 18 14.4367 18 14.9802C18 15.5453 17.5395 16 17 16H7C6.46048 16 6 15.5453 6 14.9802C6 14.4367 6.44292 14 6.96078 14C7.53473 14 8 13.5347 8 12.9608V8.94118C8 7.8776 8.87989 7 9.96053 7C10.5259 7 11 6.54324 11 5.96078ZM11.9608 3C10.6296 3 9.50357 3.87854 9.13091 5.08744C7.35353 5.46749 6 7.0423 6 8.94118V12.1611C4.8307 12.5665 4 13.6897 4 14.9802C4 16.6242 5.33038 18 7 18H9C9 19.6569 10.3432 21 12 21C13.6568 21 15 19.6569 15 18H17C18.6696 18 20 16.6242 20 14.9802C20 13.6897 19.1693 12.5665 18 12.1611V8.94118C18 7.0423 16.6465 5.46749 14.8691 5.08744C14.4964 3.87854 13.3704 3 12.0392 3H11.9608ZM13 18H11C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18Z',
      fill='#5477A9'
    )
  skif-badge(:value='isNewAvailable')
</template>

<script>
export default {
  props: {
    isNewAvailable: Number
  },
  data() {
    return {
      isPopupShowen: false
    }
  }
}
</script>
